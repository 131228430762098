/* VARIABLES CSS */
:root {
    --header-height: 3rem;

    /* Font and typography */
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;

    /* Font weight */
    --font-medium: 500;
    --font-semi-bold: 600;

    /* Margins */
    --mb-1: 0.5rem;
    --mb-2: 1rem;
    --mb-3: 1.5rem;

    /* z index */
    --z-tooltip: 10;
    --z-fixed: 100;
}

/* BASE */
*,
::before,
::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

page {
    background-color: var(--body-color);
}

body {
    margin: 0 0 var(--header-height) 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
ul,
p {
    margin: 0;
}

h1,
h2,
h3 {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

ul {
    padding: 0;
    list-style: none;
}

a {
    color: var(--text-color); /* Sets the link color to the default text color */
    transition: color 0.3s ease; /* Smooth transition for color change */

    &:hover, &:focus {
        text-decoration: underline; /* Adds underline on hover/focus for better visibility */
        color: var(--title-color); /* Changes color on hover/focus to title color for contrast */
    }
}

/* PDF Print Stuff */
.generate-pdf .page-break {
    page-break-after: always;
}

.generate-pdf body, .generate-pdf .page {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}